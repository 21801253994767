module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"chateau-edmus","short_name":"C_edmus","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/static/img/favicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"chateau-edmus","accessToken":"MC5ZVUR0ZlJBQUFDTUFyVS1j.77-9bQzvv73vv70277-977-9WjHvv70077-9Z--_vSjvv71kIG_vv73vv71o77-9LGhyOO-_vSvvv70D","previews":true,"path":"/vintage","pages":[{"type":"Vintage","match":"/vintage/:uid","path":"/vintage","component":"/opt/build/repo/src/templates/vintage.tsx"}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171705652-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1804798399673766"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
