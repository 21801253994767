// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-club-edmus-tsx": () => import("./../src/pages/club-edmus.tsx" /* webpackChunkName: "component---src-pages-club-edmus-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-domain-tsx": () => import("./../src/pages/domain.tsx" /* webpackChunkName: "component---src-pages-domain-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-notices-tsx": () => import("./../src/pages/legal-notices.tsx" /* webpackChunkName: "component---src-pages-legal-notices-tsx" */),
  "component---src-pages-new-cellar-tsx": () => import("./../src/pages/new-cellar.tsx" /* webpackChunkName: "component---src-pages-new-cellar-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-timelapses-history-tsx": () => import("./../src/pages/timelapses-history.tsx" /* webpackChunkName: "component---src-pages-timelapses-history-tsx" */),
  "component---src-templates-vintage-tsx": () => import("./../src/templates/vintage.tsx" /* webpackChunkName: "component---src-templates-vintage-tsx" */)
}

