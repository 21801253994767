import React, {useState, useEffect} from 'react';

interface stateType {
  language: string;
  adultAgree: boolean | undefined;
  cookieAgree: string | null;
  changeLanguage: (lang: string) => void;
  changeAdultAgreement: (agree: boolean) => void;
  changeCookieAgreement: (agree: boolean) => void;
  openDialog: () => void;
  closeDialog: () => void;
  dialogOpened: boolean;
}

const defaultState: stateType = {
  language: 'fr',
  adultAgree: undefined,
  cookieAgree: null,
  changeLanguage: (lang: string) => {},
  changeAdultAgreement: (agree: boolean) => {},
  changeCookieAgreement: (agree: boolean) => {},
  openDialog: () => {},
  closeDialog: () => {},
  dialogOpened: false,
};
const EdmusContext = React.createContext(defaultState);

interface PropsType {
  children: React.ReactNode;
}

const ApplicationProvider = (props: PropsType) => {
  const [dialogOpened, setDialogOpened] = useState(defaultState.dialogOpened);
  const navigatorLanguage = navigator.language.split('-')[0];

  const [language, setLanguage] = useState(
    navigatorLanguage === defaultState.language ? defaultState.language : 'en'
  );
  const [adultAgree, setAdultAgree] = useState(defaultState.adultAgree);
  const [cookieAgree, setCookieAgree] = useState<string | null>(
    defaultState.cookieAgree
  );

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    localStorage.setItem('edmusInfoLanguage', lang);
  };

  const changeAdultAgreement = (agree: boolean) => {
    setAdultAgree(agree);
    localStorage.setItem('edmusInfoAdult', String(agree));
  };

  const changeCookieAgreement = (agree: boolean) => {
    setCookieAgree(String(agree));
    localStorage.setItem('edmusInfoCookie', String(agree));
  };

  const openDialog = () => setDialogOpened(true);
  const closeDialog = () => setDialogOpened(false);

  useEffect(() => {
    const lang: string | null =
      localStorage.getItem('edmusInfoLanguage') || language;
    const isAdult: boolean = !!localStorage.getItem('edmusInfoAdult') || false;
    const acceptCookie: string | null =
      localStorage.getItem('edmusInfoCookie') !== 'undefined'
        ? localStorage.getItem('edmusInfoCookie')
        : 'wait';

    setLanguage(lang);
    setAdultAgree(isAdult);
    setCookieAgree(acceptCookie);
  }, []);

  return (
    <EdmusContext.Provider
      value={{
        language,
        adultAgree,
        cookieAgree,
        changeLanguage,
        changeAdultAgreement,
        changeCookieAgreement,
        openDialog,
        closeDialog,
        dialogOpened,
      }}
    >
      {props.children}
    </EdmusContext.Provider>
  );
};

export default EdmusContext;
export {ApplicationProvider};
