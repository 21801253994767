import 'lazysizes';
import React from 'react';
import { ApplicationProvider } from '@components/application.provider';

const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => (
  <ApplicationProvider>{element}</ApplicationProvider>
);
